import PropTypes from 'prop-types';

// Styles
import styles from './Layout404.module.css';

// Components
import OneColumnBlock from '../../atoms/OneColumnBlock/OneColumnBlock';
import Content404 from '../../atoms/Content404/Content404';

function Layout404({ children }) {
  return (
    <div className={styles.layout}>
      <OneColumnBlock>
        <Content404 />
      </OneColumnBlock>
      {children}
    </div>
  );
}

Layout404.propTypes = {
  children: PropTypes.node,
};

Layout404.defaultProps = {
  children: null,
};

export default Layout404;
